.full-page {
  height: 100vh;
}
.div-button {
  padding: 8px;
  text-align: center;
  cursor: pointer;
}
.div-button > i {
  margin-left: 10px;
}
.div-button:hover {
  background-color: #D44F15;
  color: #fff;
  transition-property: background-color color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}
.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background-color: #e8e8e8;
}
::-webkit-scrollbar-thumb {
  background-color: #D44F15;
}
.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncated.div {
  max-width: 100%;
}
.error {
  color: #ff0000;
}
.ol-tooltip-inside {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  color: black;
  opacity: 1;
  white-space: nowrap;
  font-size: 16px;
}
.tooltip-transparent {
  background-color: rgba(255, 255, 255, 0);
  display: block;
  height: 6px;
}
.toolTip-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 17px;
  left: -50px;
  min-width: 170px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup p {
  margin-top: 0;
  margin-bottom: 0;
}
