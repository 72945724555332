.workers-table__container {
  height: auto;
  margin-top: 12px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.14), 0 0 2px -2px rgba(0, 0, 0, 0.12);
}
.workers-table__container .workers-list-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 290px);
}
.workers-table__container .workers-list-grid .ag-grid__container {
  height: calc(100vh - 295px);
}
.workers-table__container .ant-table-pagination.ant-pagination {
  margin: 16px;
}
.workers-table__container__color-btn {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #676767;
}
.workers-table__container .ant-badge-status {
  line-height: normal;
}
.bulk_upload_container .full-page {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background-color: #0000001a;
}
.bulk_upload_container .ant-modal-header {
  border-bottom: none;
}
.bulk_upload_container .ant-modal-header .ant-btn-link span {
  text-decoration: underline;
}
.bulk_upload_container .ant-modal-body {
  min-height: 150px;
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bulk_upload_container .ant-modal-body .ant-upload-select {
  text-align: center;
}
.bulk_upload_container .ant-modal-footer {
  border-top: none;
  text-align: center;
}
.bulk_upload_container .validation__errors_container {
  flex-direction: column;
  width: 100%;
  height: 180px;
}
.bulk_upload_container .validation__errors_container .ant-typography {
  font-size: 13px;
}
.bulk_upload_container .validation__errors_header h5.ant-typography {
  font-size: 13px;
}
.bulk_upload_container .validation__errors__count {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.bulk_upload_container .validation__errors__reddot {
  width: 14px;
  height: 14px;
  background-color: red;
  display: inline-flex;
  border-radius: 50%;
  margin: 0 5px;
}
.bulk_upload_container .validation__errors_list {
  max-height: 140px;
  overflow-y: hidden;
}
.bulk_upload_container .validation__errors_list.show-more {
  overflow-y: scroll;
}
.bulk_upload_container .validation__errors_list__more {
  align-self: flex-end;
}
.bulk_upload_container .validation__errors_list__more span {
  text-decoration: underline;
}
.unassign__modal .ant-modal-footer,
.assign__modal .ant-modal-footer {
  text-align: center;
}
